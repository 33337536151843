<script lang="ts">
  import { _ } from "svelte-i18n";
</script>

<style type="text/css" lang="scss">
  .no-platforms {
    text-align: center;
  }
</style>

<p class="no-platforms">{$_('noPlatforms')}</p>
