<script lang="ts">
  import { onMount } from "svelte";
  import { locale, locales } from "svelte-i18n";
  import type { PlatformsTableResponse } from "./model/types";
  import CardsLayout from "./components/CardsLayout.svelte";
  import TableLayout from "./components/TableLayout.svelte";
  import PoweredBy from "./components/ui/PoweredBy.svelte";

  // Currently, kebab-case props are not supported for web components
  // svelte bug: https://github.com/sveltejs/svelte/issues/3852
  export let tableid: string;

  const API_URL = process.env.API_URL || "http://localhost:8080";
  const API_ACCEPT = "application/com.beyondp2p.api-v1.0+json";

  let fetchTableData: Promise<PlatformsTableResponse> = new Promise<
    PlatformsTableResponse
  >(() => {});

  onMount(async () => {
    // Props are not updated in onMount for web components. Using setTimeout workaround.
    // svelte bug: https://github.com/sveltejs/svelte/issues/2227
    setTimeout(() => {
      fetchTableData = (async () => {
        const response = await fetch(
          `${API_URL}/v1/publishers/components/platforms-table/${tableid}`,
          {
            headers: new Headers({
              Accept: API_ACCEPT,
            }),
          }
        );
        return await response.json();
      })().then((response) => {
        setLocale(response.settings.locale);
        return response;
      });
    }, 0);
  });

  function setLocale(localeFromApi: string) {
    if (localeFromApi == null || !$locales.includes(localeFromApi)) {
      throw new Error(
        `Illegal localeFromApi "${localeFromApi}" provided. Available locales: ${$locales.join(
          ","
        )}.`
      );
    }
    locale.set(localeFromApi);
  }
</script>

<style type="text/css" lang="scss">
  .platforms-table-component {
    .platforms {
      // xs (mobile phones)
      @media (max-width: 576px) {
        &--xs {
          display: block;
        }
        &--sm {
          display: none;
        }
      }

      // sm (landscape phones, 576px and up)
      @media (min-width: 576px) {
        &--xs {
          display: none;
        }
        &--sm {
          display: block;
        }
      }
    }
  }
</style>

<svelte:options tag="beyondp2p-platforms-table" />
{#await fetchTableData}
  <div class="platforms-table-component platforms-table-component--loading">
    <p>Loading platforms table</p>
  </div>
{:then response}
  <link
    rel="stylesheet"
    type="text/css"
    href={response.settings.bundleCssUrl} />
  <link rel="stylesheet" type="text/css" href={response.settings.themeCssUrl} />

  <div class="platforms-table-component platforms-table-component--success">
    <!-- xs (mobile) -->
    <div class="platforms platforms--xs">
      <CardsLayout platforms={response.platforms} />
    </div>

    <!-- sm (tablet/desktop) -->
    <div class="platforms platforms--sm">
      <TableLayout platforms={response.platforms} />
    </div>

    <PoweredBy />
  </div>
{:catch error}
  <div class="platforms-table-component platforms-table-component--error">
    <p>Something went wrong: {error.message}</p>
  </div>
{/await}
