<script lang="ts">
  import { _ } from "svelte-i18n";
  import type { Platform } from "../model/types.ts";
  import {
    comparePlatformByRating,
    userClickedVisitPlatformButton,
  } from "../service/utils";
  import RatingStars from "./ui/RatingStars.svelte";
  import RatingAverage from "./ui/RatingAverage.svelte";
  import RatingCount from "./ui/RatingCount.svelte";
  import NoPlatforms from "./ui/NoPlatforms.svelte";
  import AverageNetAnnualReturn from "./ui/AverageNetAnnualReturn.svelte";
  import CardsPlatformDetails from "./card/CardEntryDetails.svelte";

  export let platforms: Platform[] = [];
</script>

<style type="text/css" lang="scss">
  // cards (xs)
  .platform-card {
    margin-bottom: 16px;
    padding: 4px 12px;
    border-radius: 5px;
    box-shadow: var(--card-shadow);
    transition: 0.3s;

    &__visit {
      display: flex;
      justify-content: center;
    }
  }
</style>

{#each platforms.sort(comparePlatformByRating) as platform}
  <div class="platform-card" data-tid={platform.id}>
    <h2 class="platform-card__name">{platform.name}</h2>
    <div class="p rating">
      {#if platform.rating.ratingsCount > 0}
        <div>
          <RatingStars averageRatingHalf={platform.rating.averageRatingHalf} />
          <RatingAverage averageRating={platform.rating.averageRating} />
        </div>
        <RatingCount ratingsCount={platform.rating.ratingsCount} />
      {:else}
        <RatingStars averageRatingHalf={platform.rating.averageRatingHalf} />
        <RatingCount ratingsCount={platform.rating.ratingsCount} />
      {/if}
    </div>

    <AverageNetAnnualReturn
      averageNetAnnualReturn={platform.averageNetAnnualReturn} />

    <CardsPlatformDetails {platform} />

    <p class="platform-card__visit">
      <button
        class="button"
        rel="sponsored"
        on:click={() => userClickedVisitPlatformButton(platform.affiliateLink)}>
        {$_('signUpForFree')}
      </button>
    </p>
  </div>
{:else}
  <NoPlatforms />
{/each}
