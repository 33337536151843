import { init, addMessages, getLocaleFromNavigator } from "svelte-i18n";
import de from "./lang/de.json";
import en from "./lang/en.json";
import es from "./lang/es.json";
import "./Main.wc.svelte";
addMessages("de", de);
// default
addMessages("en", en);
addMessages("es", es);
init({
    fallbackLocale: "en",
    initialLocale: getLocaleFromNavigator(),
});
