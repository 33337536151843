export function comparePlatformByRating(a, b) {
    const ratingA = a.rating.averageRating;
    const ratingB = b.rating.averageRating;
    if (ratingA > ratingB) {
        return -1;
    }
    else if (ratingA < ratingB) {
        return 1;
    }
    else {
        return 0;
    }
}
export function userClickedVisitPlatformButton(affiliateLink) {
    window.location.assign(affiliateLink);
}
