<script lang="ts">
  import type { Platform } from "../../model/types";
  import { _, number } from "svelte-i18n";
  import { ChevronUp, ChevronDown } from "svelte-bootstrap-icons";
  import type { PlatformDetailsData } from "../../model/types";

  export let platform: Platform;

  let showDetails: boolean = false;

  function userToggledShowPlatformDetails() {
    showDetails = !showDetails;
  }

  let data: PlatformDetailsData[];
  $: {
    const {
      country,
      investingInto,
      totalLoansFunded,
      investors,
      currency,
    } = platform;

    data = [
      {
        cssModifier: "country",
        label: $_("country"),
        value: country,
        show: country != null,
      },
      {
        cssModifier: "investing-into",
        label: $_("investingInto"),
        value: investingInto.map(({ displayName }) => displayName).join(", "),
        show: investingInto.length > 0,
      },
      {
        cssModifier: "total-loans-funded",
        label: $_("totalLoansFunded"),
        value: `${$number(totalLoansFunded)} ${currency}`,
        show: totalLoansFunded != null && currency != null,
      },
      {
        cssModifier: "investors",
        label: $_("investors"),
        value: $number(investors),
        show: investors != null,
      },
    ].map((data) => ({
      ...data,
      cssModifier: makeCssModifier(data.cssModifier),
    }));
  }

  function makeCssModifier(cssModifier: string): string {
    return `entry-details--${cssModifier}`;
  }
</script>

<style type="text/css" lang="scss">
  .entry-details {
    display: grid;
    grid-template-rows: auto auto;

    &__label {
      border-top: 2px solid var(--light-color);
      border-bottom: 1px solid var(--light-color);
      background-color: var(--lightest-color);
      padding: 4px;
      font-weight: bold;
    }

    &__value {
      padding: 4px;
    }
  }

  .entry-details-toggle {
    cursor: pointer;
    font-weight: bold;
    text-align: center;
  }
</style>

<p
  class="entry-details-toggle"
  class:entry-details-toggle--show={showDetails}
  on:click={userToggledShowPlatformDetails}>
  {#if showDetails}
    {$_('details.hide')}
    <ChevronUp />
  {:else}
    {$_('details.show')}
    <ChevronDown />
  {/if}
</p>

{#if showDetails}
  {#each data as d}
    <div class="entry-details {d.cssModifier} p">
      <div class="entry-details__label">{d.label}</div>
      <div class="entry-details__value">
        {#if d.show}{d.value}{:else}n/a{/if}
      </div>
    </div>
  {/each}
{/if}
