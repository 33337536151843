<script lang="ts">
  import { _ } from "svelte-i18n";
  import { ChevronUp, ChevronDown } from "svelte-bootstrap-icons";
  import type { Platform } from "../../model/types";
  import AverageNetAnnualReturn from "../ui/AverageNetAnnualReturn.svelte";
  import RatingStars from "../ui/RatingStars.svelte";
  import RatingAverage from "../ui/RatingAverage.svelte";
  import RatingCount from "../ui/RatingCount.svelte";
  import { userClickedVisitPlatformButton } from "../../service/utils";
  import TableEntryDetails from "./TableEntryDetails.svelte";

  export let platform: Platform;
  let showDetails: boolean = false;

  function userToggledShowPlatformDetails() {
    showDetails = !showDetails;
  }
</script>

<style type="text/css" lang="scss">
  .entry-container {
    border-bottom: 2px solid var(--light-color);

    .entry {
      cursor: pointer;

      &:hover {
        background-color: var(--lighter-color);
      }

      &:last-child {
        border-bottom: 0;
      }

      &__visit {
        // shrink to fit
        width: 0.1%;
        white-space: nowrap;
      }

      &__details {
        text-align: center;
      }
    }
  }
</style>

<tbody class="entry-container">
  <tr
    class="entry"
    data-tid={platform.id}
    on:click={userToggledShowPlatformDetails}>
    <td class="entry__name">
      <h2>{platform.name}</h2>
    </td>
    <td class="entry__average-net-return">
      <AverageNetAnnualReturn
        averageNetAnnualReturn={platform.averageNetAnnualReturn} />
    </td>
    <td>
      <div class="entry__rating">
        {#if platform.rating.ratingsCount > 0}
          <div>
            <RatingStars
              averageRatingHalf={platform.rating.averageRatingHalf} />
            <RatingAverage averageRating={platform.rating.averageRating} />
          </div>
          <RatingCount ratingsCount={platform.rating.ratingsCount} />
        {:else}
          <RatingStars averageRatingHalf={0.0} />
          <RatingCount ratingsCount={0} />
        {/if}
      </div>
    </td>
    <td class="entry__visit">
      <button
        class="button"
        rel="sponsored"
        on:click={() => userClickedVisitPlatformButton(platform.affiliateLink)}>
        {$_('signUp')}
      </button>
    </td>
    <td class="entry__details">
      {#if showDetails}
        <ChevronUp />
      {:else}
        <ChevronDown />
      {/if}
    </td>
  </tr>
  {#if showDetails}
    <tr>
      <td colspan="5">
        <TableEntryDetails {platform} />
      </td>
    </tr>
  {/if}
</tbody>
