<script lang="ts">
  export let averageRating: number;
</script>

<style type="text/css" lang="scss">
  .rating-average {
    display: inline-block;
    min-width: 36px;
    padding: 2px;
    text-align: center;
    color: var(--primary-color);
    font-size: 1.2em;
    font-weight: bold;
    border: 2px solid var(--primary-color);
    border-radius: 5px;
  }
</style>

<span class="rating-average">{averageRating.toFixed(1)}</span>
