<script lang="ts">
  import { onMount } from "svelte";

  interface StarsRating {
    empty: number;
    half: number;
    full: number;
  }

  export let averageRatingHalf: number;
  let starsRating: RatingStars;

  onMount(async () => {
    starsRating = calcRatingStars(averageRatingHalf);
  });

  function calcRatingStars(ratingHalf: number): RatingStars {
    if (!ratingHalf) {
      return {
        empty: 5,
        half: 0,
        full: 0,
      };
    }

    const maximum = 5;
    const divisor = 1;
    const remainder = ratingHalf % divisor;

    const fullStarsCount = Math.floor(ratingHalf / divisor);
    const hasHalfStar = remainder !== 0;

    let halfStarsCount = 0;
    let emptyStarsCount = maximum - fullStarsCount;
    if (hasHalfStar) {
      halfStarsCount = 1;
      const newEmptyStarsCount = emptyStarsCount - 1;
      emptyStarsCount = newEmptyStarsCount > 0 ? newEmptyStarsCount : 0;
    }

    return {
      empty: emptyStarsCount,
      half: halfStarsCount,
      full: fullStarsCount,
    };
  }
</script>

<style type="text/scss">
  .rating-stars {
    display: inline-flex;
    margin-right: 4px;
    &--full,
    &--half {
      color: var(--stars-color);
    }
    &--empty {
      color: var(--light-color);
    }
  }
</style>

<span class="rating-stars">
  {#if starsRating != null}
    <span class="rating-stars--full">
      {Array(starsRating.full)
        .fill()
        .map(() => '★')
        .join('')}
    </span>
    <span class="rating-stars--half">
      {Array(starsRating.half)
        .fill()
        .map(() => '☆')
        .join('')}
    </span>
    <span class="rating-stars--empty">
      {Array(starsRating.empty)
        .fill()
        .map(() => '☆')
        .join('')}
    </span>
  {/if}
</span>
