<script lang="ts">
  import { _ } from "svelte-i18n";
  export let ratingsCount: number;
</script>

<style type="text/css" lang="scss">
  .rating-count {
    color: var(--light-color);
  }
</style>

{#if ratingsCount > 0}
  <div class="rating-count">
    {ratingsCount}
    {ratingsCount === 1 ? $_('ratings.rating') : $_('ratings.ratings')}
  </div>
{:else}
  <div class="rating-count">{$_('ratings.noRatings')}</div>
{/if}
