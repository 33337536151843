<script lang="ts">
  import { _ } from "svelte-i18n";
</script>

<style type="text/css" lang="scss">
  .powered-by {
    font-size: 90%;
    color: var(--light-color);

    a {
      color: var(--light-color);
    }
  }
</style>

<p class="powered-by">
  {@html $_('poweredBy', {
    values: {
      link: `<a href="https://beyondp2p.com" target="_blank" rel="noopener follow">beyondp2p.com</a>`,
    },
  })}
</p>
