<script lang="ts">
  import { _, number } from "svelte-i18n";
  import type { Platform, PlatformDetailsData } from "../../model/types";

  export let platform: Platform;

  let data: PlatformDetailsData[];
  $: {
    const {
      country,
      investingInto,
      totalLoansFunded,
      investors,
      currency,
    } = platform;

    data = [
      {
        cssModifier: "country",
        label: $_("country"),
        value: country,
        show: country != null,
      },
      {
        cssModifier: "investing-into",
        label: $_("investingInto"),
        value: investingInto.map(({ displayName }) => displayName).join(", "),
        show: investingInto.length > 0,
      },
      {
        cssModifier: "total-loans-funded",
        label: $_("totalLoansFunded"),
        value: `${$number(totalLoansFunded)} ${currency}`,
        show: totalLoansFunded != null && currency != null,
      },
      {
        cssModifier: "investors",
        label: $_("investors"),
        value: $number(investors),
        show: investors != null,
      },
    ].map((data) => ({
      ...data,
      cssModifier: makeCssModifier(data.cssModifier),
    }));
  }

  function makeCssModifier(cssModifier: string): string {
    return `entry-details--${cssModifier}`;
  }
</script>

<style type="text/css" lang="scss">
  .entry-details {
    display: grid;
    grid-template-columns: 25% auto;
    border-top: 1px solid var(--light-color);
    padding: 8px 0;

    &__label {
      font-weight: bold;
    }

    &__value {
    }
  }
</style>

{#each data as d}
  <div class="entry-details {d.cssModifier}">
    <div class="entry-details__label">{d.label}</div>
    <div class="entry-details__value">
      {#if d.show}{d.value}{:else}n/a{/if}
    </div>
  </div>
{/each}
