<script lang="ts">
  import { _ } from "svelte-i18n";

  export let averageNetAnnualReturn: number;
</script>

<style type="text/css" lang="scss">
  .average-net-annual-return {
    &__percentage {
      color: var(--primary-color);
      font-size: 1.2em;
      font-weight: bold;
    }
  }
</style>

<div class="average-net-annual-return">
  {#if averageNetAnnualReturn}
    <div class="average-net-annual-return__percentage">
      {averageNetAnnualReturn?.toFixed(1)}%
    </div>
    <div class="average-net-annual-return__rate">{$_('perYear')}</div>
  {:else}
    <div class="average-net-annual-return__percentage">n/a</div>
  {/if}
</div>
