<script lang="ts">
  import { _ } from "svelte-i18n";
  import type { Platform } from "../model/types.ts";
  import { comparePlatformByRating } from "../service/utils";
  import NoPlatforms from "./ui/NoPlatforms.svelte";
  import TableEntry from "./table/TableEntry.svelte";

  export let platforms: Platform[];
</script>

<style type="text/css" lang="scss">
  // table (sm)
  .platforms-table {
    width: 100%;
    border-collapse: collapse;

    &__head {
      text-transform: capitalize;
    }
  }
</style>

<table class="platforms-table">
  <thead>
    <tr class="platforms-table__head">
      <th>{$_('platform')}</th>
      <th>{$_('returns')}</th>
      <th>{$_('ratings.ratings')}</th>
      <th />
      <th />
    </tr>
  </thead>
  {#each platforms.sort(comparePlatformByRating) as platform}
    <TableEntry {platform} />
  {:else}
    <tbody>
      <tr>
        <td colspan="5">
          <NoPlatforms />
        </td>
      </tr>
    </tbody>
  {/each}
</table>
